/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import { ParseContent } from 'components/shared'
import Breadcrumb from 'components/elements/BreadCrumb'

// Elements
import { FeatureContainer, FeatureTitle, FeatureContent } from './home'
import KnowItem from '../components/elements/KnowItem'

const Hero = styled.section`
  background-size: 100% 400px;

  @media screen and (max-width: 991px) {
    background-size: cover !important;
  }
`

const HeroContent = styled.div`
  padding-top: 200px;
`

const Sidebar = styled.div`
  background: white;
  border-radius: 25px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <Hero className="hero-image-small" style={{
          backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`
        }}>
        <HeroContent className="container">
          <div className="mb-3">
            <ParseContent
              className="color-light text-center text-lg-left"
              content={acf.banner.description}
            />
          </div>
          <Breadcrumb data={title} className="py-0" pageContext={pageContext} />
        </HeroContent>
      </Hero>

      <div className="container py-5">
        <div className="px-lg-5">
          <ParseContent content={acf.meta.intro_description} />
        </div>
      </div>

      <div className="container my-3">
        <div className="row mb-5 align-content-stretch">
          {acf.usps.map(({ title: uspTitle, description }) => {
            return (
              <div key={uspTitle} className="col-lg-3 col-md-6">
                <div className="h-100 px-lg-3 mb-5 mb-lg-0">
                  <FeatureContainer className="h-100">
                    <div className="d-flex align-items-center">
                      <span className="my-auto">
                        <FeatureTitle content={`<h2>${uspTitle}</h2>`} />
                      </span>
                    </div>
                    <div className="mt-3">
                      <FeatureContent content={description} />
                    </div>
                  </FeatureContainer>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      
      {acf.knowitem && (
        <KnowItem id={acf.knowitem} />
      )}

      <div className="container pt-lg-5 pb-5">
        <div className="px-lg-5">
          <div className="row">
            <div className="col-lg-8 pr-lg-5">
              <ParseContent content={acf.meta.description} />
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0 pb-5">
              <Sidebar className="p-4">
                <ParseContent content={acf.meta.sidebar_description} />
              </Sidebar>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          background {
          localFile {
            publicURL
            childImageSharp {
              fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                src
              }
            }
          }
        }
        }

        usps {
          title
          description
        }

        meta {
          intro_description
          description
          sidebar_description
        }

        knowitem
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
