import { useStaticQuery, Link } from 'gatsby';
import React from 'react'
import styled from 'styled-components';
import { ParseContent } from 'components/shared'
import Plaatjie from '@ubo/plaatjie';

const StyledKnowItem = styled.div`
  @media (min-width: 992px){
    margin-bottom: 13rem;
  }
`

const SolutionContainer = styled.div`
  margin-top: 90px;
  background: white;
  border-radius: 25px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

  @media (min-width: 992px) {
    padding: 10px 100px 80px;
  }

  @media (max-width: 991px) {
    padding: 10px .5rem 0px .5rem;
  }
`

const SolutionContent = styled(ParseContent)`
  font-family: ${(props) => props.theme.font.family.secondary};
  line-height: ${(props) => props.theme.font.size.l};
`

const SolutionTitle = styled.div`
  font-size: ${(props) => props.theme.font.size.xl};
  font-weight: ${(props) => props.theme.font.weight.l};
  line-height: ${(props) => props.theme.font.size.xxxl};
  color: ${(props) => props.theme.color.text.main};
  background: white;
  padding: 0 20px;
  position: relative;
  margin-top: -35px;
  width: fit-content;
  margin-bottom: 40px;
`

const SolutionImage = styled.div`
  & > div {
    position: absolute !important;
    margin-top: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .5);

    @media screen and (max-width: 992px) {
      position: relative !important;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
`

const KnowItem = ({ id }) => {
  const {
    allKnowitems: { edges }
  } = useStaticQuery(graphql`
    {
      allKnowitems: allWordpressWpWeetjes {
        edges {
          node {
            wordpress_id
            title
            acf {
              block {
                title
                description
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 850, quality: 100, placeholder: NONE)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const filteredId = edges.filter(({ node} ) => node.wordpress_id === id)

  return (
    <StyledKnowItem>
      <div className="container">
        <div className="px-lg-5 pb-5">
          {filteredId.map(( item ) => (
            <SolutionContainer>
            <SolutionTitle>
              {item.node.acf.block.title}
            </SolutionTitle>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-lg-12">
                <SolutionContent content={item.node.acf.block.description} />
              </div>
              <div className="col-lg-6 d-flex justify-content-end">
                <SolutionImage>
                  <Plaatjie
                    image={item.node.acf.block.image}
                    style={{
                      width: '100%',
                      height: 250,
                      borderRadius: 25,
                      objectFit: 'cover',
                    }}
                    alt={item.node.acf.block.image.title}
                  />
                </SolutionImage>
              </div>
              {/* <div className="col-lg-5 pl-lg-2 my-5 my-lg-2">
               
              </div> */}
            </div>
          </SolutionContainer>
          ))}
        </div>
      </div>
    </StyledKnowItem>
  )
}

export default KnowItem